import { defaultLocale, locales } from '@/i18n';
import { createNavigation } from 'next-intl/navigation';
import { defineRouting } from 'next-intl/routing';

export const routing = defineRouting({
  locales,
  defaultLocale,
  localeCookie: {
    // Expire the cookie after 1 year, we inform users about the cookie
    maxAge: 60 * 60 * 24 * 365,
  },
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter } =
  createNavigation(routing);
