import { APIExceptionData, ErrorType } from '@diamond-block/errors';

export type FrontendExceptionData = Omit<APIExceptionData, 'type'> & {
  type: ErrorType | FrontendErrorType;
};

export interface APIMeta<T extends 'success' | 'failure'> {
  status: T;
  pagination?: {
    limit: number;
    offset: number;
    total_count: number;
  };
}

export interface SuccessfulAPIResponse<T> {
  meta: APIMeta<'success'>;
  data: T;
}

export interface FailedAPIResponse {
  meta: APIMeta<'failure'>;
  data: APIExceptionData[];
}

export type APIResponse<T> = SuccessfulAPIResponse<T> | FailedAPIResponse;

export type IctoNumber = {
  ictoNumber: string;
};

export type Organisation = {
  orgId: string;
  name: string;
  billingTypes: BillingType[];
  minBillingItems: number;
  maxBillingItems: number;
  segment?: Segment | null;
};

export type Segment = {
  segmentId: number;
  name: string;
  organisations?: Organisation[];
};

export type User = {
  userId: number;
  email?: string;
  gitlabId?: number | null;
  keycloakId?: string;
};

export type GroupMeta = {
  groupId: number;
  name: string;
  path: string;
  requester: User | null;
  admins: User[];
  billingItems: BillingItem[];
  createdAt: string;
  updatedAt: string;
  recertifiedAt: string;
  licenseSeats: number;
  userCountCurrent: number;
  orgId: string;
  organisation?: Organisation;
  approved: boolean;
  description?: string;
  recertifications?: Array<{ recertId: string }>;
};

export type Recertification = {
  recertId: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  mailCount: number;
  gitlabGroup: GroupMeta;
  active: boolean;
};

export enum GitLabTokenScope {
  ReadAPI = 'ReadAPI', // all read scopes
  ReadWriteAPI = 'ReadWriteAPI', // read/write API access
  ReadAll = 'ReadAll', // read all resources
  ReadWriteAll = 'ReadWriteAll', // read/write all resources
}

export enum ResourceStatus {
  Requested = 'Requested',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export type Resource = {
  id: number;
  status: ResourceStatus;
  groupId: number;
  type: 'Generic' | 'ServiceAccount';
  requester?: User;
  requesterId: number;
  serviceAccount?: {
    username: string;
    hasGitLab: boolean;
    hasJFrog: boolean;
    isAdmin: boolean;
    ticketReference: string;
  };
  gitlabGroup: GroupMeta;
  generic?: {
    data: string;
  };
};

export type GitlabToken = {
  createdAt: string;
  expiresAt: string;
  description: string;
  gitlabId: number;
  id: number;
  requesterId: number;
  requester: {
    email: string;
  };
  revoked: boolean;
  serviceAccount?: {
    username?: string;
  };
  scope: GitLabTokenScope;
  token?: string;
};

export type JFrogToken = {
  createdAt: string;
  expiresAt: string;
  description: string;
  jfrogId: string;
  id: number;
  requesterId: number;
  requester: {
    email: string;
  };
  revoked: boolean;
  serviceAccount?: {
    username?: string;
  };
  token?: string;
};

export type BillingType = {
  billingTypeId: number;
  name: string;
  description: string;
  creatable: boolean;
  searchable: boolean;
  regex: string | null;
  priority: number;
};

export type BillingItem = {
  billingItemId?: string;
  value: string;
  billingType: BillingType;
};

export enum FrontendErrorType {
  _UnknownError = '_UnknownError',
  _InvalidSession = '_InvalidSession',
}

export const UnknownError: FrontendExceptionData = {
  message: 'Unknown error',
  type: FrontendErrorType._UnknownError,
};

export const SessionError: FrontendExceptionData = {
  message: 'Session error',
  type: FrontendErrorType._InvalidSession,
};

export type IssuedJFrogData = {
  referenceToken: string;
  expiresIn: number;
};

export interface RequestToken {
  requestToken: string;
}

export interface ExtendedRequestToken extends RequestToken {
  confirmToken: string;
}

export interface ChangeZAMPasswordData {
  email: string;
  currentPassword: string;
  newPassword: string;
}

export interface KeycloakCredentials {
  email: string;
  password: string;
}

export interface PrivilegedRequestData {
  email: string;
}

export type RealmType = 'primary' | 'aurora';
export type CredentialType = 'otp' | 'webauthn';

export interface ResetCredentialData {
  realm: RealmType;
  credential: CredentialType;
}
